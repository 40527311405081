import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Header from "./components/Header/Header";
import FooterNav from "./components/FooterNav/FooterNav";
import Start from "./pages/Start";
import Welcome from "./pages/Welcome/Welcome";
import Phone from "./pages/Phone/Phone";
import Knowledge from "./pages/Knowledge/Knowledge";
import Types from "./pages/Knowledge/Types";
import Violence from "./pages/Knowledge/Violence";
import Chat from "./pages/Chat/Chat";
import AbuseWitnessPage from "./pages/Knowledge/AbuseWitnessPage";
import AntiAbusePhonePage from "./pages/Knowledge/AntiAbusePhonePage";
import VideoPageInfo from "./pages/Knowledge/VideoPageInfo";
import VideoPageMyths from "./pages/Knowledge/VideoPageMyths";
import VideoPageSign from "./pages/Knowledge/VideoPageSign";
import VideoPageHelp from "./pages/Knowledge/VideoPageHelp";
import VideoPageStories from "./pages/Knowledge/VideoPageStories";
import LangSelect from "./components/LangSelect/LangSelect";
//import Page404 from "./pages/404/Page404";

function AppRoutes() {

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Navigate to="/pl" />} />
        <Route path="/:locale" element={<Welcome />} />
        <Route path="/:locale/start" element={<Start />} />
        <Route path="/:locale/kontakt" element={<Phone />} />
        <Route path="/:locale/chat" element={<Chat />} />
        <Route path="/:locale/wiedza" element={<Knowledge />} />
        <Route path="/:locale/rodzaje-przemocy" element={<Types />} />
        <Route path="/:locale/rodzaje-przemocy/:type" element={<Violence />} />
        <Route path="/:locale/swiadek-przemocy" element={<AbuseWitnessPage />} />
        <Route path="/:locale/telefon-przeciwprzemocowy" element={<AntiAbusePhonePage />} />
        <Route path="/:locale/podstawowe-informacje-o-przemocy-i-staystyki" element={<VideoPageInfo />} />
        <Route path="/:locale/mity-i-stereotypy" element={<VideoPageMyths />} />
        <Route path="/:locale/miedzynarodowy-znak-pomoz-mi" element={<VideoPageSign />} />
        <Route path="/:locale/skuteczna-pomoc" element={<VideoPageHelp />} />
        <Route path="/:locale/historie-avon" element={<VideoPageStories />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <FooterNav />
    </Router>
  );
}

export default AppRoutes;
