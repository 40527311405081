import React from 'react';
import DATA from '../../data/ui.json'
import PHONE from '../../../assets/icons/phone-black.svg'
import { useAppSelector } from '../../hooks';
import { selectUI } from '../../features/ui/uiSlice';
import Button from '../../components/Button';
import './Phone.scss';

function Phone() {
  const ui = useAppSelector(selectUI);

    return (
      <div className="page phone-page">
        <div className="page-inner">
          <p className="description">{DATA.contact.text}</p>
          {/*<Button type="icon" link={"/"+ui.locale+"/kontakt"}>
            <div className="icon">
              <img src="/assets/icons/phone.svg" />
            </div>
            <span>{DATA.contact.app}</span>
    </Button>*/}
          {/*<a className="btn icon-btn" type="icon" href="tel:888883388">
            <div className="icon">
              <img src="/assets/icons/phone.svg" />
            </div>
            <span>{DATA.home.call}</span>
  </a>*/}
          <div className="phone">
            <img src={PHONE} />
            <span>888 88 33 88</span>
          </div>
          <p>{ DATA.contact.hours }</p>
        </div>
      </div>
    );
}

export default Phone;