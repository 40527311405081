export function getCookie(sName) {
  var oCrumbles = document.cookie.split(';');
  for(var i=0; i<oCrumbles.length;i++){
      var oPair= oCrumbles[i].split('=');
      var sKey = decodeURIComponent(oPair[0].trim());
      var sValue = oPair.length>1 ? oPair[1] : '';
      if(sKey === sName) {
          return decodeURIComponent(sValue);
      }
  }
  return '';
}

export function setCookie(sName, sValue, options) {
  var sCookie = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);
  if (options && options instanceof Date) {
      options = {
          expires: options
      };
  }
  if (options && typeof options === 'object') {
      if (options.expires) {
          sCookie += '; expires=' + options.expires.toGMTString();
      }
      if (options.path) {
          sCookie += '; path=' + options.path.toString();
      }
      if (options.domain) {
          sCookie += '; domain=' + options.domain.toString();
      }
      if (options.secure) {
          sCookie += '; secure';
      }
  }
  document.cookie= sCookie;
}

export function removeCookie(sName, options) {
  var opt = options || {};
  opt.expires = new Date();
  this.setCookie(sName, '', opt);
}

export function sendFloodEvent(sendTo, path) {
  if(window.gtag) {
    window.gtag('event', 'conversion', path ? {
      'allow_custom_scripts': true,
      'u1': path,
      'send_to': sendTo
    } : {
      'allow_custom_scripts': true,
      'send_to': sendTo
    });
  }
}