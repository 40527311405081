import React from 'react';
import DATA from '../../data/ui.json';
import iconHeader from './img/swiadek_przemocy.svg';

function AbuseWitnessPage() {
  const data = DATA.knowledge.abuseWitness;

  return (
    <div className="page">
      <div className="page-inner">
        <div className="abuse-witness">
          <h1 className="with-icon">
            <div className="icon">
              <img src={iconHeader} />
            </div>
            <span>{data.header}</span>
          </h1>
          <p>{ data.text }</p>
          <h2>{ data.header2 }</h2>
          <ul className="abuse-list">
          { data.items.map((item, key)=>{
            return <li key={ 'item-'+key }><span className="number">{ key+1 }</span><span dangerouslySetInnerHTML={{ __html: item }}></span></li>
          })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AbuseWitnessPage;