import React from 'react';
import DATA from '../../data/ui.json';
import { useParams } from 'react-router-dom';
import icon1 from './img/fizyczna.svg';
import icon2 from './img/seksualna.svg';
import icon3 from './img/psychiczna.svg';
import icon4 from './img/ekonomiczna.svg';
import icon5 from './img/ograniczenie.svg';

function Violence() {
  const params = useParams()
  const data = DATA.knowledge.abuseTypes[params.type]
  const icons = {
    "fizyczna": icon1,
    "seksualna": icon2,
    "psychiczna": icon3,
    "ekonomiczna": icon4,
    "bezprawne-ograniczenie-wolnosci": icon5
  }

  return (
    <div className="page">
      <div className="page-inner knowledge-page">
        <h1 className="with-icon">
          <div className="icon">
            <img src={icons[params.type]} />
          </div>
          <span>{ data.title }</span>
        </h1>
        <p>{ data.description }</p>
        { data.link ? <iframe width="100%" height="auto" src={"https://www.youtube.com/embed/"+data.link} title="Jak skutecznie pomagać kobietom z doświadczeniem przemocy cz. 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> : null }
        { data.effectsTitle ? <h2>{ data.effectsTitle }</h2> : null }
        { data.effectsDescription ? <p>{ data.effectsDescription }</p> : null }
        { data.exampleTitle ? <h2>{ data.exampleTitle }</h2> : null }
  { data.exampleDescription ? <p>{ data.exampleDescription }</p> : null }
      </div>
    </div>
  );
}

export default Violence;