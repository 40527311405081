import { createSlice } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store';

export interface UiState {
  showMenu: boolean,
  showDrawer: boolean,
  drawerType: 'error'|'info',
  drawerData: any,
  locale: string
}

const initialState: UiState = {
  showMenu: false,
  showDrawer: false,
  drawerType: 'error',
  drawerData: { name: "błąd", errorCode: ''},
  locale: "pl"
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openMenu: (state) => {
      state.showMenu = true;
    },
    closeMenu: (state) => {
      state.showMenu = false;
    },
    showDrawer: (state, action) => {
      state.showDrawer = true;
      state.drawerType = action.payload.type ? action.payload.type : state.drawerType;
      state.drawerData = action.payload.data ? action.payload.data : state.drawerData;
    },
    hideDrawer: (state) => {
      state.showDrawer = false;
    },
    setLocale: (state, action) => {
      state.locale = action.payload
    }
  }
});

export const { openMenu, closeMenu, showDrawer, hideDrawer, setLocale } = uiSlice.actions;

export const selectUI = (state: RootState) => state.ui
export const selectMenu = (state: RootState) => state.ui.showMenu

export default uiSlice.reducer;
