import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Button extends Component {

  render() {
    if( !this.props.action ) {
      return (
        <NavLink to={this.props.link} className={"btn "+this.props.type+"-btn"} >{ this.props.children }</NavLink>
      )
    } else {
      return (
        <NavLink to={this.props.link} className={"btn "+this.props.type+"-btn"} onClick={ this.props.action }>{ this.props.children }</NavLink>
      );
    }
  }
}

export default Button;