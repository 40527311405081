import React, { useEffect, useState } from 'react';
import Button from '../components/Button';
import { setCookie, getCookie } from '../Helpers';
import DATA from '../data/ui.json'
import { useAppSelector } from '../hooks';
import { selectUI } from '../features/ui/uiSlice';

function Start() {
  const [popup, setPopup]  = useState(!getCookie("apopup"))
  const ui = useAppSelector(selectUI)
  const data = DATA.home;

  const closePopup = () => {
    if (window.gtag) {
      window.gtag('event', "click", { event_category: "start", event_label: 'data_processing_popup' })
    }
    let date = new Date()
    date.setDate(date.getDate() + 1)
    setPopup(false)
    setCookie("apopup", "true", date)
  }

  return (
    <div className="page">
      <div className="page-inner">
        <h1>{ data.welcome }</h1>
        <p><strong>{ data.text1 }</strong></p>
        <p>{ data.text2 }</p>
        <h2>{ data.selectTitle }</h2>
        <Button type="icon" action={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "start", event_label: 'start_zadzwon' })} } } link={"/"+ui.locale+"/kontakt"}>
          <div className="icon">
            <img src="/assets/icons/phone.svg" />
          </div>
          <span>{data.call}</span>
        </Button>
        <Button type="icon" action={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "start", event_label: 'start_napisz' })} } } link={"/"+ui.locale+"/chat"}>
          <div className="icon">
            <img src="/assets/icons/write.svg" />
          </div>
          <span>{data.write}</span>
        </Button>
        {/*<a target="_blank" href="https://feminoteka.pl/mapa-miejsc-pomocy-dla-kobiet-doswiadczajacych-przemocy/" className={"btn icon-btn"} onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "start", event_label: 'start_znajdz' })} } }>
          <div className="icon icon-map">
            <img src="/assets/icons/map.svg" />
          </div>
          <span>{data.shelter}</span>
  </a>*/}
        <Button type="icon" action={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "start", event_label: 'start_more' })} } } link={"/"+ui.locale+"/wiedza"}>
          <div className="icon">
            <img src="/assets/icons/more.svg" />
          </div>
          <span>{data.more}</span>
        </Button>
        { popup ? <div className="popup">
          <div className="popup-inner">
            <p>{ data.popup.start }<br/> <strong>{ data.popup.name }</strong>.</p>
            <p>{ data.popup.text }</p>
            <button onClick={ () => { closePopup() } } className="btn center">Ok</button>
          </div>
        </div> : null }
      </div>
    </div>
  );
}

export default Start;