import React from 'react';
import { NavLink } from 'react-router-dom';
import DATA from '../../data/ui.json';
import icon1 from './img/rodzaje_przemocy.svg';
import icon2 from './img/swiadek_przemocy.svg';
import icon3 from './img/phone.svg';
import icon4 from './img/statystyki.svg';
import icon5 from './img/mity.svg';
import icon6 from './img/znak.svg';
import icon7 from './img/skuteczna_pomoc.svg';
import icon8 from './img/historie.svg';
import { useAppSelector } from '../../hooks';
import { selectUI } from '../../features/ui/uiSlice';

function Knowledge() {
  const ui = useAppSelector(selectUI);

  return (
    <div className="page">
      <div className="page-inner knowledge-page">
        <h1>{ DATA.knowledge.title}</h1>
        <p>{ DATA.knowledge.text }</p>
        <ul className="types-nav">
          <li>
            <NavLink className="btn icon-btn" to={"/"+ui.locale+"/rodzaje-przemocy"} onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "more", event_label: 'rodzaje_przemocy' })} } }>
              <div className="icon">
                <img src={icon1} />
              </div>
              <span>{DATA.knowledge.abuseTypes.name}</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="btn icon-btn" to={"/"+ui.locale+"/swiadek-przemocy"} onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "more", event_label: 'swiadek_przemocy' })} } }>
              <div className="icon">
                <img src={icon2} />
              </div>
              <span>{DATA.knowledge.abuseWitness.header}</span>
            </NavLink>
          </li>
          <li><NavLink className="btn icon-btn" to={"/"+ui.locale+"/telefon-przeciwprzemocowy"} onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "more", event_label: 'telefon_przeciwprzemocowy' })} } }>
          <div className="icon">
                <img src={icon3} />
              </div>
              <span>{DATA.knowledge.abusePhone.name}</span>
            </NavLink>
          </li>
          <li><NavLink className="btn icon-btn" to={"/"+ui.locale+"/podstawowe-informacje-o-przemocy-i-staystyki"} onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "more", event_label: 'podstawowe_informacje_o_przemocy' })} } }>
            <div className="icon">
                <img src={icon4} />
              </div>
              <span>{DATA.knowledge.videoInfo.header}</span>
            </NavLink>
            </li>
          <li><NavLink className="btn icon-btn" to={"/"+ui.locale+"/mity-i-stereotypy"} onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "more", event_label: 'mity_i_stereotypy' })} } }>
            <div className="icon">
                <img src={icon5} />
              </div>
              <span>{DATA.knowledge.videoMyths.header}</span>
            </NavLink>
          </li>
          <li><NavLink className="btn icon-btn" to={"/"+ui.locale+"/miedzynarodowy-znak-pomoz-mi"} onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "more", event_label: 'miedzynarodowy_znak' })} } }>
            <div className="icon">
                <img src={icon6} />
              </div>
              <span>{DATA.knowledge.videoSign.header}</span>
            </NavLink>
          </li>
          <li><NavLink className="btn icon-btn" to={"/"+ui.locale+"/skuteczna-pomoc"} onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "more", event_label: 'skuteczna_pomoc' })} } }>
            <div className="icon">
                <img src={icon7} />
              </div>
              <span>{DATA.knowledge.videoHelp.header}</span>
            </NavLink>
          </li>
          <li><NavLink className="btn icon-btn" to={"/"+ui.locale+"/historie-avon"} onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "more", event_label: 'historie_avon' })} } }>
            <div className="icon">
                <img src={icon8} />
              </div>
              <span>{DATA.knowledge.videoStories.header}</span>
            </NavLink>
            </li>
        </ul>
      </div>
    </div>
  );
}

export default Knowledge;