import React from 'react'
import './Header.scss'
import { useLocation } from 'react-router-dom';
import LOGO from '../../../assets/logos-white.svg';

function Header() {
  const location = useLocation()

  return (
    <header className={location.pathname.length < 4 ? "header hide" : "header"}>
      <div className="logo"><img src={LOGO} alt="Avon Kontra Przemoc"/></div>
    </header>
  )
}

export default Header;