import React from 'react';

function AbuseWitness({data}) {
  const d = data.knowledge.abuseWitness;

  return (
    <div className="abuse-witness">
      <h3>{ d.header }</h3>
      <p>{ d.text }</p>
      <h4>{ d.header2 }</h4>
      <ul className="abuse-list">
      { d.items.map((item, key)=>{
        return <li key={ 'item-'+key }><span className="number">{ key+1 }</span><span dangerouslySetInnerHTML={{ __html: item }}></span></li>
      })}
      </ul>
    </div>
  );
  
}

export default AbuseWitness;