import React from 'react';
import DATA from '../../data/ui.json';
import iconHeader from './img/statystyki.svg';

function VideoPageInfo() {
  const data = DATA.knowledge.videoInfo;

  return (
    <div className="page">
      <div className="page-inner knowledge-page">
        <h1 className="with-icon">
          <div className="icon">
            <img src={iconHeader} />
          </div>
          <span>{data.header}</span>
        </h1>
        <p>{ data.text }</p>
        <iframe width="100%" height="auto" src={"https://www.youtube.com/embed/"+data.link} title="Jak skutecznie pomagać kobietom z doświadczeniem przemocy cz. 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  );
}

export default VideoPageInfo;