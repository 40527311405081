import React, { useEffect, useRef } from 'react';
import AbuseWitness from '../../components/AbuseWitness';
import AntiAbusePhone from '../../components/AntiAbusePhone';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addAnswerToHistory, selectChatCurrent, selectChatHistory, selectChatID, selectChatPoints } from '../../features/chat/chatSlice';
import CHAT from '../../data/chat.json';
import './Chat.scss';

function Chat() {
  const dispatch = useAppDispatch();
  const chatHistory = useAppSelector(selectChatHistory);
  const current = useAppSelector(selectChatCurrent);
  const points = useAppSelector(selectChatPoints)
  const chatID = useAppSelector(selectChatID);
  const inputRef = useRef(null);

  useEffect(()=> {
    window.scrollTo({ top: document.getElementsByClassName('chat-page')[0].clientHeight, behavior: 'smooth' })
    
    if (chatHistory.length > 0 ) {
      if (chatHistory[chatHistory.length-1].next === "END2" || chatHistory[chatHistory.length-1].next=== "END3" || chatHistory[chatHistory.length-1].next=== "END4" || chatHistory[chatHistory.length-1].next=== "END_EMPTY") {
        saveChatHistory(chatHistory)
      } else {
        saveChatHistory([chatHistory[chatHistory.length-1]])
      }
    }
  },[chatHistory])
  
  const saveChatHistory = (history) => {
    fetch('https://api.avonalert.pl/save', {
        method: 'POST',
        headers: {"Content-Type": "application/json; charset=utf-8"},
        body: JSON.stringify({ident: chatID, history: history})
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
        /*success(result)
        if ( result?.data?.error === "error_unauthorized") {
            localStorage.removeItem("token");
            window.location.replace('/')
        }*/
        console.log('history saved')
    }, (error) => {
        //failed(error)
    })
  }

  const conversationFromHistory = () =>  {
    let messages = [];
    chatHistory.forEach((item,key)=>{
      if( item.q_id === 'Q8') {
        messages.push(<li key={ 'msg-'+key } className="message system-message"><AbuseWitness data={ CHAT } /></li>)
        messages.push(<li key={ 'msg-'+key } className="message system-message"><AntiAbusePhone data={ CHAT } /></li>)
      } else {
        if (item.q_value && item.q_value != '') {
          messages.push(<li key={ 'msg-'+key } className="message system-message"><p className="message-bubble">{ item.q_value }</p></li>)
        }
      }
      if( item.answer ) {
        messages.push(<li key={ 'answer-'+key } className="message user-message"><p className="message-bubble">{ item.answer.name }</p></li>)
      }
    })
    return messages;
  }

  const displayLastMessage = () =>  {
    const message = CHAT.messages[current];
    let question = null;
    let answers = null;

    switch(message.type) {
      case 'question':
        if (message.question && message.question.length != '') {
          question = <p className="message-bubble">{ message.question }</p>
        }
        if (current === 'Q8') {
          question = <div><AbuseWitness data={ CHAT }/><AntiAbusePhone data={ CHAT }/></div>
        }
        answers = (
          <ul className="answers">
            { message.answers.map((answer, key) => {
              return <li key={ current+'-answer-'+key } className="answer" onClick={ ()=>{ dispatch(addAnswerToHistory({'q_value': message.question, 'q_id': current, answer: {'value': answer.value, 'name': answer.name}, 'next': answer.next})) }}>{ answer.name }</li>
            })}
          </ul>
        )
        break;
       case 'question-call':
        question = <p className="message-bubble">{ message.question }</p>
        answers = (
          <ul className="answers">
            { message.answers.map((answer, key) => {
              let next = answer.next;
                let currentdate = new Date(); 
              if ( currentdate.getHours() < 20 ) {
                next = 'END_EMPTY';
                return <li key={ current+'-answer-'+key } className="answer answer-call"><a href="tel:888883388" onClick={ ()=>{ if (window.gtag) {window.gtag('event', "click", { event_category: "chat", event_label: 'btn_zadzwon' })};
                  dispatch(addAnswerToHistory({'q_value': message.question, 'q_id': current, answer: {'value': answer.value, 'name': answer.name}, 'next': next}))
                }}>{ answer.name }</a></li>
              } else {
                return <li key={ current+'-answer-'+key } className="answer" onClick={ ()=>{ 
                  dispatch(addAnswerToHistory({'q_value': message.question, 'q_id': current, answer: {'value': answer.value, 'name': answer.name}, 'next': next}) )
                }}>{ answer.name }</li>
              }
            })}
          </ul>
        )
        break;
      case 'input':
        if (message.input === 'textarea') {
          question = <p className="message-bubble"><textarea rows="5" style={{fontSize: '16px'}} ref={ inputRef } name={ current } placeholder={ message.placeholder }></textarea></p>
        } else {
          question = <p className="message-bubble"><input type={ message.input } ref={ inputRef } name={ current } placeholder={ message.placeholder } /></p>
        }
        answers = (
          <ul className="answers">
            { message.answers.map((answer, key) => {
              return <li key={ current+'-answer-'+key } className="answer" onClick={ ()=>{ dispatch(addAnswerToHistory({'q_id': current, answer: {'name': inputRef.current.value}, 'next': answer.next})) }}>{ answer.name }</li>
            })}
          </ul>
        )
        break;
      case 'end':
        let endMessage = message.endMessages[0]
        if ( points >= 1 && points <= 2) {
          endMessage = message.endMessages[1]
        }
        if ( points > 2 ) {
          endMessage = message.endMessages[2]
        }
        question = <p className="message-bubble">{ endMessage }</p>
        answers = (
          <ul className="answers">
            { message.answers.map((answer, key) => {
              let next = answer.next;
                let currentdate = new Date(); 
              if ( currentdate.getHours() < 20 ) {
                next = 'END_EMPTY';
                return <li key={ current+'-answer-'+key } className="answer answer-call"><a href="tel:888883388" onClick={ ()=>{ if (window.gtag) {window.gtag('event', "click", { event_category: "chat", event_label: 'btn_zadzwon' })};
                  dispatch(addAnswerToHistory({'q_value': message.question, 'q_id': current, answer: {'value': answer.value, 'name': answer.name}, 'next': next}))
                }}>{ answer.name }</a></li>
              } else {
                return <li key={ current+'-answer-'+key } className="answer" onClick={ ()=>{ 
                  dispatch(addAnswerToHistory({'q_value': message.question, 'q_id': current, answer: {'value': answer.value, 'name': answer.name}, 'next': next}))
                }}>{ answer.name }</li>
              }
            })}
          </ul>
        )
        break;
      /*case 'end':
        question = <p className="message-bubble">{ message.question }</p>
        answers = (
          <ul className="answers">
            { message.answers.map((answer, key) => {
              return <li key={ current+'-answer-'+key } className="answer" onClick={ ()=>{ addAnswerToHistory({'q_value': message.question, 'q_id': current, answers: [{'a_value': answer.value, 'a_name': answer.name}], 'next': answer.next}) }}>{ answer.name }</li>
            })}
          </ul>
        )
        break;*/
      default:
        question = '';
        answers = '';
        break;
    }

    return (
      <li className={message.type === 'input' ? "message system-message input-message" : "message system-message" }>
        { question }
        { answers }
      </li>
    )
  }

  return (
    <div className="page chat-page">
      <div className="page-inner">
        <div className="chat-window">
          <ul className="chat-messages">
            { chatHistory.length > 0 ? conversationFromHistory() : null }
            { displayLastMessage() }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Chat;