import React from 'react';

function AntiAbusePhone({data}) {
  const d = data.knowledge.abusePhone;

  return (
    <div className="abuse-witness">
      <h3>{ d.header }</h3>
      <p dangerouslySetInnerHTML={{ __html: d.text }}></p>
    </div>
  );

}

export default AntiAbusePhone;