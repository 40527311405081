import React, { useEffect, useState } from 'react';
import { generateID } from "../../features/chat/chatSlice";
import Button from '../../components/Button';
import { getCookie, setCookie } from '../../Helpers';
import { useAppDispatch, useAppSelector, useWindowDimensions } from '../../hooks';
import DATA from '../../data/ui.json';
import './Welcome.scss'
import { selectUI } from '../../features/ui/uiSlice';
import LOGOS from '../../../assets/logos-black.svg'

function Welcome() {
  const dispatch = useAppDispatch();
  const [popup, setPopup]  = useState(!getCookie("wpopup"))
  const {height} = useWindowDimensions();
  const ui = useAppSelector(selectUI)

  const onStart = () => {
    if (window.gtag) {
      window.gtag('event', "click", { event_category: "welcome", event_label: 'rozpocznij' })
    }
    if(!localStorage.getItem('chat')) {
      dispatch(generateID())
    }
  }

  const closePopup = () => {
    if (window.gtag) {
      window.gtag('event', "click", { event_category: "welcome", event_label: 'partners_popup' })
    }
    let date = new Date()
    date.setDate(date.getDate() + 1)
    setPopup(false)
    setCookie("wpopup", "true", date)
  }

  return (
    <>
      <div className="welcome-page" style={{height: height}}>
        <Button type="start" link={"/"+ui.locale+"/start"} action={ onStart() }>{DATA.welcome.start}</Button>
      </div>
      { popup && <div className="popup welcome-popup">
          <div className="popup-inner">
            <img src={LOGOS} style={{margin: '0 auto'}}/>
            <p className="larger"><strong>{ DATA.welcome.popup.name }</strong>{' '+DATA.welcome.popup.lead }</p>
            <p>{ DATA.welcome.popup.text }</p>
            <p><strong>{ DATA.welcome.popup.text2 }</strong></p>
            <button onClick={ () => { closePopup() } } className="btn center">Ok</button>
          </div>
        </div> }
    </>
  );
}

export default Welcome;