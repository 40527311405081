import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import uiReducer from './features/ui/uiSlice';
import chatReducer from './features/chat/chatSlice';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    chat:chatReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
