import React from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import { clearHistory } from "../../features/chat/chatSlice";
import "./Footer.scss";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectUI } from '../../features/ui/uiSlice';
import HOME from '../../../assets/home.svg';
import BACK from '../../../assets/back.svg';
import EXIT from '../../../assets/close.svg';
import DATA from '../../data/ui.json';

function FooterNav() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const ui = useAppSelector(selectUI)

  return (
    <div className={location.pathname.length < 4 ? "footer-nav hide" : "footer-nav"}>
      <div className="nav">

          <a className="back" href="/" onClick={ (e)=>{ e.preventDefault(); window.history.back(); if (window.gtag) {window.gtag('event', "click", { event_category: "menu", event_label: 'wstecz' })}; } }>
            <div className="footer-nav-icon">
              <img src={BACK} alt=""/>
            </div>
            <span>{DATA.back}</span>
          </a>
        
          <NavLink className="home" to={"/"+ui.locale+"/start"} onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "menu", event_label: 'start' })} } }>
            <div className="footer-nav-icon">
              <img src={HOME} alt=""/>
            </div>
          </NavLink>
        
          <NavLink className="leave" to={"/"+ui.locale} onClick={ () => { dispatch(clearHistory()); if (window.gtag) {window.gtag('event', "click", { event_category: "menu", event_label: 'zakoncz' })} } }>
            <span>{DATA.leave}</span>
            <div className="footer-nav-icon">
              <img src={EXIT} alt=""/>
            </div>
          </NavLink>
        
      </div>
    </div>
  );
}

export default FooterNav;