import React from 'react';
import { NavLink } from 'react-router-dom';
import DATA from '../../data/ui.json';
import icon1 from './img/fizyczna.svg';
import icon2 from './img/seksualna.svg';
import icon3 from './img/psychiczna.svg';
import icon4 from './img/ekonomiczna.svg';
import icon5 from './img/ograniczenie.svg';
import iconHeader from './img/rodzaje_przemocy.svg';
import { useAppSelector } from '../../hooks';
import { selectUI } from '../../features/ui/uiSlice';

function Types() {
  const ui = useAppSelector(selectUI);

  return (
    <div className="page">
      <div className="page-inner knowledge-page">
        <h1 className="with-icon" style={{marginLeft: 12}}>
          <div className="icon">
            <img src={iconHeader} />
          </div>
          <span>{DATA.knowledge.abuseTypes.name}</span>
          </h1>
        <ul className="types-nav">
          <li>
            <NavLink className="btn icon-btn" onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "przemoc", event_label: 'fizyczna' })} } } to={"/"+ui.locale+"/rodzaje-przemocy/fizyczna"}>
              <div className="icon">
                <img src={icon1} />
              </div>
              <span>{DATA.knowledge.abuseTypes.fizyczna.name}</span>
            </NavLink>
          </li>
          <li><NavLink className="btn icon-btn" onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "przemoc", event_label: 'seksualna' })} } } to={"/"+ui.locale+"/rodzaje-przemocy/seksualna"}>
              <div className="icon">
                <img src={icon2} />
              </div>
              <span>{DATA.knowledge.abuseTypes.seksualna.name}</span>
            </NavLink>
          </li>
          <li><NavLink className="btn icon-btn" onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "przemoc", event_label: 'psychiczna' })} } } to={"/"+ui.locale+"/rodzaje-przemocy/psychiczna"}>
            <div className="icon">
                <img src={icon3} />
              </div>
              <span>{DATA.knowledge.abuseTypes.psychiczna.name}</span>
            </NavLink>
          </li>
          <li><NavLink className="btn icon-btn" onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "przemoc", event_label: 'ekonomiczna' })} } } to={"/"+ui.locale+"/rodzaje-przemocy/ekonomiczna"}>
              <div className="icon">
                <img src={icon4} />
              </div>
              <span>{DATA.knowledge.abuseTypes.ekonomiczna.name}</span>
            </NavLink>
          </li>
          <li><NavLink className="btn icon-btn" onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "przemoc", event_label: 'bezprawne_oganiczenie_wolnosci' })} } } to={"/"+ui.locale+"/rodzaje-przemocy/bezprawne-ograniczenie-wolnosci"}>
              <div className="icon">
                <img src={icon5} />
              </div>
              <span>{DATA.knowledge.abuseTypes["bezprawne-ograniczenie-wolnosci"].name}</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Types;