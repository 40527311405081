import React, { useEffect, useState } from 'react';
import Routes from '../Routes';
import { useAppSelector, useWindowDimensions } from '../hooks';
import { selectUI } from '../features/ui/uiSlice';

function App() {
  const [isIframe, setIsIFrame] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ui = useAppSelector(selectUI)
  const {height} = useWindowDimensions();

  useEffect(()=>{
    /*const params = getQueryParams(window.location.search)
    if ( params.iframe === '1' ) {
      setIsIFrame(true);
    }*/
  },[]);

  //const getQueryParams = (qs) => {
    /*qs = qs.split('+').join(' ');

    var params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;*/
  //}

  const openWidget = () => {
    window.parent.postMessage({
      'func': 'av_openWidget',
      'message': ''
    }, "*");
    setIsOpen(true)
  }

  const closeWidget = () => {
    window.parent.postMessage({
      'func': 'av_closeWidget',
      'message': ''
    }, "*");
    setIsOpen(false)
  }

  if (isIframe) {
    if (isOpen) {
      return (
        <div className="app widget-closed">
          <div className="widget-item" onClick={ ()=>openWidget() }>Reaguj na <br/><span>przemoc</span><span className="logo-widget"><img src="/assets/icons/logo_widget.svg"/></span><span className="arrow"><img src="/assets/icons/arrow_white.svg"/></span></div>
        </div>
      )
    } else {
      return (
        <div className={ui.showMenu ? "app menu-open" : "app"} style={{minHeight: height}}>
          <Routes />
          <div className="close-widget" onClick={ ()=>closeWidget() }></div>
        </div>
      )
    }

  } else {
    return (
      <div className={ui.showMenu ? "app menu-open" : "app"} style={{minHeight: height}}>
        <Routes />
      </div>
    )
  }
}

export default App;
