import { createSlice } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store';

export interface UiState {
  id: boolean,
  history: any,
  current: string,
  points: number
}

const initialState: UiState = {
  id: false,
  history: [],
  current: "Q1",
  points: 0
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    generateID: (state: any) => {
      state.id = '_' + Math.random().toString(36).substr(2, 9);
    },
    addAnswerToHistory: (state: any, action: any) => {
      state.history = action.payload.next === "Q1" ? [] : [...state.history, action.payload];
      state.current = action.payload.next
      state.points = action.payload.next === "Q1" ? 0 : state.points + action.payload.answer.value
    },
    clearHistory: (state: any, action: any) => {
      state.history = []
      state.points = 0 
      state.current = "Q1"
    },
  }
});

export const { generateID, addAnswerToHistory, clearHistory } = chatSlice.actions;

export const selectChatID = (state: RootState) => state.chat.id
export const selectChatHistory = (state: RootState) => state.chat.history
export const selectChatCurrent = (state: RootState) => state.chat.current
export const selectChatPoints = (state: RootState) => state.chat.points

export default chatSlice.reducer;
