import React from 'react';
import DATA from '../../data/ui.json';
import iconHeader from './img/phone.svg';

function AntiAbusePhonePage() {
  const data = DATA.knowledge.abusePhone;

  return (
    <div className="page">
      <div className="page-inner">
        <h1 className="with-icon">
          <div className="icon">
            <img src={iconHeader} />
          </div>
          <span>{data.header}</span>
        </h1>
        <p dangerouslySetInnerHTML={{ __html: data.text }}></p>
        <h2>{data.header2}</h2>
        <p dangerouslySetInnerHTML={{ __html: data.text2 }}></p>
        <a href="tel:888 88 33 88" className="btn icon-btn" onClick={ () => { if (window.gtag) {window.gtag('event', "click", { event_category: "telefon_przeciwprzemocowy", event_label: 'zadzwon' })} } }>
          <div className="icon">
            <img src="/assets/icons/phone.svg" />
          </div>
          <span>{DATA.home.call}</span>
        </a>
      </div>
    </div>
  );
}

export default AntiAbusePhonePage;